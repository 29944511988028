<template>

</template>

<style lang="scss">

</style>

<script>

export default {
  components: {
  },
  data () {
    return {
      orders:[]
    }
  },
    created() {
      this.load_orders();
    },
    methods: {
      load_orders()
      {
          this.$http.get(
              this.$http.defaults.baseURL + '/bi/pre-pis/').then((response) => {
              console.log(response);
          })
              .catch((error) => {
                  this.notification = true;
                  this.notification_message = 'Error: Please contact administrator';
                  console.log("Error");
                  console.log(error);
              });
      }
    }
}
</script>
